@mixin for-galaxy-fold {
    @media (max-width: 306px) { @content; }
  }
  @mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
  }
  
  @mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
  }

  @mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
  }

  @mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
  }

  @mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
  }

  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

#kota-text {
  padding: 0px 20px;
  font-size: 0.9em;

  @include for-tiny-phone-only {
      font-size: 0.9em;
      padding: 5px 5px 15px 5px;
      text-align: center;
  }
}

#footer-wrapper {
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: #282C34;

  @include for-tiny-phone-only {
    height: fit-content;
  }
}

#footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;

    @include for-tiny-phone-only {
      flex-direction: column-reverse;
  }
}

#questions-text {
  padding: 0px 20px;
  font-size: 0.9em;
  text-align: right;

  @include for-tiny-phone-only {
    text-align: center;
    padding: 15px 0px 5px 0px;
  }
}