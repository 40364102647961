@mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
  }
  
  @mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
  }

  @mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
  }

  @mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
  }

  @mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
  }

  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
  
#header-wrapper {
    width: 100%;
    height: 50px;
    z-index: 1;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);

    @include for-galaxy-fold-landscape {
        height: 40px;
        min-height: 40px;
    }

    #header-logo-wrapper {
        display: flex;
        width: 75%;
        height: 75%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
    }
}

#installer-logo {
  transform: scale(1);
  max-height: 100%;

  @include for-galaxy-fold-landscape {
      transform: scale(0.85);
  }
}

#kota-logo {
    transform: scale(1.125);

    @include for-galaxy-fold-landscape {
        transform: scale(0.85);
    }
}