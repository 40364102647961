@mixin for-ipad-portrait {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { @content; }
  }

  @mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
  }
  
  @mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
  }

  @mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
  }

  @mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
  }

  @mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
  }

  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
  
#outer-body-wrapper {
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
}

#page-description {
    text-align: center;
    line-height: 1.5em;
    width: 90%;
    align-self: center;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    background-color: white;

    @include for-tablet-landscape-up {
        width: 20%;
        padding: 0px 40px 10px 25px;
        background-color: #ededed;
        height: 100%;
        height: -moz-available;          /* WebKit-based browsers will ignore this. */
        height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        height: fill-available;
        

        @include for-ipad-portrait {
            text-align: center;
            line-height: 1.5em;
            width: auto;
            align-self: flex-start;
            font-size: 0.9em;
            display: flex;
            flex-direction: column;
            padding-right: 0px;
            height: auto;
            background-color: white;
        }

        @include for-desktop-up {
            font-size: 1em;
        }
    }

    #main-paragraph {
        padding: 0px 20px;
    }
}

#body-wrapper {

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-tablet-landscape-up {
        flex-direction: row;
    }

    @include for-ipad-portrait {
        flex-direction: column;
    }
    
}

#customer-address {

    font-weight: bold;
    padding: 20px 5px 20px 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: monospace;
    font-size: 1.5em;

    @include for-tiny-phone-only {
        font-size: 1.25em;
    }
}

#design-image {
    height: auto;
    width: auto;
    max-width: 90%;
    max-height: 300px;
    align-self: center;
}

#battery-image {
  height: auto;
  width: auto;
  padding-top: 15px;
  max-width: 90%;
  max-height: 300px;
  align-self: center;
}


#image-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 80%;
}

#click-text {
    padding: 20px 0px 10px 0px;
    font-style: italic;
    font-weight: normal;
    font-family: monospace;
}

.break {
    height: 10px;

    @include for-tablet-landscape-up {
        height: 25px;
    }
}

#outer-outer-wrapper {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}