@mixin for-tiny-phone-only {
  @media (max-width: 350px) { @content; }
}

@mixin for-galaxy-fold-landscape {
  @media (max-height: 322px) { @content; }
}

@mixin for-small-phone-landscape {
  @media (max-height: 420px) { @content; }
}

@mixin for-phone-landscape {
  @media (max-height: 532px) { @content; }
}

@mixin for-522-portrait {
  @media (max-height: 522px) { @content; }
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin for-galaxy-fold-double {
  @media (max-width: 513px) and (max-height: 718px) and (min-width: 511px) and (min-height: 716px){ @content; }
}

@mixin for-ipad {
  @media (min-width: 763px) and (min-height: 1024px){ @content; }
}


@mixin for-galaxy-fold {
  @media (max-width: 654px) and (max-height: 281px) { @content; }
}

@mixin for-galaxy-fold-2 {
  @media (max-width: 714px) and (max-height: 513px) { @content; }
}

@mixin for-surface-duo-2 {
  @media (min-width: 719px) and (min-height: 1113px) { @content; }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#app-wrapper {
  height:100%;
  width:100%;

  

  @include for-tablet-landscape-up {
    height: 100vh;
  }

  @include for-galaxy-fold {
    height: auto;
  }

  @include for-galaxy-fold-2 {
    height: auto;
  }

  @include for-surface-duo-2 {
    height: 100vh;
  }

}