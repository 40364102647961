#default-page-text-wrapper {
    line-height: 2em;
    text-align: center;
    padding: 0px 10px;
}

#rep-email {
    font-weight: 700;
    color: #D6172B;
}

#default-page-wrapper {
    height: 100vh;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}