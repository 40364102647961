@mixin for-ipad-portrait {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { @content; }
}

@mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
}
  
@mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
}

@mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
}

@mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
}

@mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
}

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 650px) { @content; }
}

@mixin likelinesstext-1 {
    @media (min-width: 845px) { @content; }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 965px) { @content; }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}
  
#rating-numbers-sections-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#rating-numbers-section-1 {
    display: flex;
}

#rating-numbers-section-2 {
    display: flex;
}

#ratings-text-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#rating-question {
    text-align: center;
}

#submit-button-wrapper {
    padding-top: 30px;
}

#rating-content-wrapper {
    padding: 5% 5% 5% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-tablet-landscape-up {
        padding: 10% 10% 0px 10%;
    }


    #comment-area {
        width: 90%;
        max-width: 600px;
        height: 10em;
        resize: none;
        border-radius: 5px;
        padding: 10px;
        margin-top: 20px;
        font-family: Lato, sans-serif;
        font-size: 1rem;
    }
}

#rating-value-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 620px;

    .rating-value-individual-wrapper {
        padding: 2px 2px;
    }

    .rating-value {
        width: 45px;
        min-width: 40px;
        height: 45px;
        min-height: 40px;
        border: 1px solid #282C34;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            //background-color: #fff9aa;
            background-color: #282C34;
            transition: 0.3s;
            color: #f7f7f7;
        }
    }
}