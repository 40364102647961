@mixin for-galaxy-fold {
    @media (max-width: 306px) { @content; }
  }
  @mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
  }
  
  @mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
  }

  @mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
  }

  @mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
  }

  @mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
  }

  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
  
#thank-you-text {
    text-align: center;
    padding: 0px 10px 30px 10px;
}

#positive-thank-you-text-wrapper {
    text-align: center;
    padding: 0px 10px;

}

#copy-to-clipboard {
    padding-top: 25px;

    @include for-galaxy-fold-landscape {
        padding-top: 5px;
    }
}

#review-icons {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    padding-top: 30px;

    @include for-phone-only {
        flex-direction: column;
        width: auto;
        height: 40%;
        align-items: center;
    }

    @include for-galaxy-fold-landscape {
        padding-top: 0px;
    }


    #google-icon {
        padding: 0px 15px;
        height: 3.5em;
        width: auto;

        @include for-galaxy-fold-landscape {
            height: 3em;
        }

        @include for-phone-only {
            padding-bottom: 20px;
            height: auto;
            width: 70%;
            max-width: 250px;
        }

        #google-icon-image {
            height: 100%;
            cursor: pointer;

            @include for-phone-only {
                height: auto;
                width: 100%;
            }
        }
    }

    #yelp-icon {
        padding: 0px 15px;
        height: 3.5em;
        width: auto;

        @include for-galaxy-fold-landscape {
            height: 3em;
        }

        @include for-phone-only {
            padding-bottom: 20px;
            height: auto;
            width: 70%;
            max-width: 250px;
        }

        #yelp-icon-image {
            height: 100%;
            cursor: pointer;

            @include for-phone-only {
                height: auto;
                width: 100%;
            }
        }
    }
}

#thank-you-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5% 5% 5%;

    @include for-galaxy-fold-landscape {
        padding: 0px 5%;
    }
}

#positive-thank-you-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5% 5% 5%;

    @include for-galaxy-fold-landscape {
        padding: 0px 5%;
    }
}
