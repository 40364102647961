@mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
  }
  
  @mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
  }

  @mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
  }

  @mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
  }

  @mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
  }

  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

  // *** Portal *** //

  #portal-main-wrapper {
    display: flex;
    // flex-direction: column;

    @include for-phone-only {
      display: block;
    }

    #form-component {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-between;

      #header-strip {
        background-color: #F5ECE6;
        width: 100%;
        height: 13px;
        display: list-item;
      }

      #portal-proof-section-wrapper {
        display: flex;
        flex-direction: column;
      }

      #footer-section {
        background-color: #1F1F1F;
        height: 90px;
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        bottom: 0px;
        position: relative;

        @include for-phone-only {
          flex-direction: column-reverse;
          height: fit-content;
          padding-top: 10px;
        }

        #luna-copyright {
          color: white;
          flex: flex-start;
          padding: 20px 40px;
          place-self: center;
          font-size: 14px;

          @include for-phone-only {
            padding-bottom: 30px;
          }
        }

        #other-questions-block {
          color: white;
          flex: flex-end;
          font-size: 14px;
          padding: 20px 40px 20px 20px;

          @include for-phone-only {
            padding: 10px 0px;
          }

          a {
            color: white;
            text-decoration: underline;
            font-size: 14px;
            transition: 0.2s;

            &:hover {
              color: white;
              text-shadow: 0 0 10px white;
              transition: 0.2s;

              &:after {
                
              }
            }
          }
        }
      }
    }
  }

  #review-paragraphs-wrapper {
    padding-top: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 14px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    #close-wrapper {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 10px;

      #popup-header {
        display: inline-block;
        align-self:center;
        font-weight: bold;
        padding-bottom: 5px;
        padding-top: 5px;
        font-family: 'Avenir-Black', serif;
        letter-spacing: 1.5px;
      }
    
      .close {
        display: flex;
        justify-self: flex-end;
        right:18px;
        position: absolute;
        background: none;
        border: none;
        font-size: 20px;
        color: slategray;
        cursor: pointer;
      }
    }

    #approve-modal-content {
      padding: 0px 15px;
      white-space: pre-wrap;
      font-size: 14px;

      @include for-tablet-landscape-up {
        font-size: 16px;
      }
    }

    #revision-modal-content {
      padding: 0px 15px;
      font-size: 14px;
      text-align: center;

      @include for-galaxy-fold-landscape {
        display: none;
      }
    }

    #checkbox-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      @include for-phone-only {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 10px;
      }

      #understood-checkbox {
        margin-right: 13px;
      }

      .understood-checkbox-container {

        @include for-phone-only {
          padding-bottom: 10px;
          justify-content: flex-start;
        }
      }

      #approve-error-message {
        width: max-content;
        padding-left: 5px;
        padding-right: 10px;
        color: red; 
      }

      .popup-approve-button {
        display: inline-block;

        @include for-phone-only {
          align-self: center;
        }
      }
    }
  }
  .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 500px; //this was 30%
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius:10px;
    display: block;
    max-height: 90%;
    overflow-wrap: break-word;

    @include for-phone-only {
      width: 90vw;
      max-width: 500px;
      min-width: 250px;
      max-height: fit-content;
    }
  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  

  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }

  
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  cursor: pointer;
  display: flex; 
  justify-content: space-evenly;
  align-items: center;
}

input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  position: relative;
  border-radius:5px;
  border: 1px solid black;
  background: white;
  width: 20px;
  height: 20px;
  margin:0;
  text-align: center;
  margin-right: 10px;
}

input[type="checkbox"]:checked + label:before {
  content: "✓";
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  color: white;
  background: #89a48a;
  z-index: 10;
}

#request-box-wrapper {
    display:flex;
    justify-content: center;
    flex-direction: column;
  }
  
  #request-changes-box {
    resize: none;
    overflow:visible;
    width: 95%;
    height: 100%;
    min-height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    align-self: center;
    border-radius: 15px;
    border-color: lightgrey;
    border-width: 3px;
  }
  
  textarea::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 0px 5px 5px 0px;
      background-color: #F5F5F5;
  }
  
  textarea::-webkit-scrollbar
  {
      width: 12px;
      background-color: #F5F5F5;
  }
  
  textarea::-webkit-scrollbar-thumb
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #282C34;
  }
  
  textarea:focus-visible {
    outline: none;
  }
  
  
  