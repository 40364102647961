@mixin for-tiny-phone-only {
    @media (max-width: 350px) { @content; }
  }
  
  @mixin for-galaxy-fold-landscape {
    @media (max-height: 322px) { @content; }
  }

  @mixin for-small-phone-landscape {
    @media (max-height: 420px) { @content; }
  }

  @mixin for-phone-landscape {
    @media (max-height: 532px) { @content; }
  }

  @mixin for-522-portrait {
    @media (max-height: 522px) { @content; }
  }

  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
  

  #button-wrapper-1 {
    display: flex;
    align-self: center;
    padding: 15px 0px;
    place-content: center;
    width: 100%;
  
    #button-wrapper-2 {
      display: flex;
      justify-content: center;
      height: 46px;
      flex-direction: row;
    
      @include for-phone-only {
        flex-direction: column;
        height: fit-content;
      }
    
      button {
        margin: 0px 20px;
    
        @include for-phone-only {
          margin: 10px 0px;
        }
      }
    }
  }


  .approveButton {
    align-items: center;
    background-color: white;
   // border: 2px solid #37B86D;
    border: 2px solid #37B86D;
    border-radius: 8px;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    min-width: 150px;
    transition: 0.2s;
  }
  // Green 37B86D
  // Redc9656c
  
  .approveButton:active:hover {
    background-color: #12753A;
    outline: 0;
    color: white;
    border-color: #12753A;
  }
  
  .approveButton:active {
    background-color: #12753A;
    outline: 0;
    color: white;
    border-color: #12753A;
  }

  .approveButton:disabled {
    background-color: #e9e7e7;
    cursor: default;
    color: #383838;
    border-color: #e9e7e7;
  }
  
  .approveButton:disabled:hover {
    background-color: #e9e7e7;
    cursor: default;
    color: #383838;
    border-color: #e9e7e7;
  }
  
  .approveButton:hover {
    outline: 0;
    background-color: #37B86D;
    color: white;
  }

  .approveButton-red {
    align-items: center;
    background-color: white;
   // border: 2px solid #37B86D;
    border: 2px solid #EA8271;
    border-radius: 8px;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    min-width: 150px;
    transition: 0.2s;
  }

  .approveButton-red:active:hover {
    background-color: #bb5748;
    outline: 0;
    color: white;
    border-color: #bb5748;
  }
  
  .approveButton-red:active {
    background-color: #bb5748;
    outline: 0;
    color: white;
    border-color: #bb5748;
  }

  .approveButton-red:disabled {
    background-color: #e9e7e7;
    cursor: default;
    color: #383838;
    border-color: #e9e7e7;
  }
  
  .approveButton-red:disabled:hover {
    background-color: #e9e7e7;
    cursor: default;
    color: #383838;
    border-color: #e9e7e7;
  }
  
  .approveButton-red:hover {
    outline: 0;
    background-color: #EA8271;
    color: white;
  }

  .approveButton-orange {
    align-items: center;
    background-color: white;
   // border: 2px solid #37B86D;
    border: 2px solid #FDBC5E;
    border-radius: 8px;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    min-width: 150px;
    transition: 0.2s;
  }

  .approveButton-orange:active:hover {
    background-color: #be7f27;
    outline: 0;
    color: white;
    border-color: #be7f27;
  }
  
  .approveButton-orange:active {
    background-color: #be7f27;
    outline: 0;
    color: white;
    border-color: #be7f27;
  }

  .approveButton-orange:disabled {
    background-color: #e9e7e7;
    cursor: default;
    color: #383838;
    border-color: #e9e7e7;
  }
  
  .approveButton-orange:disabled:hover {
    background-color: #e9e7e7;
    cursor: default;
    color: #383838;
    border-color: #e9e7e7;
  }
  
  .approveButton-orange:hover {
    outline: 0;
    background-color: #FDBC5E;
    color: black;
  }

  .request-revision-button {
    align-items: center;
    background-color: white;
    border: 2px solid #DB3C21;
    border-radius: 8px;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    min-width: 150px;
  
      @include for-phone-only {
        width: 100%;
        height: 50px;
        margin: 10px 0px;
      }
  
    &:hover {
      outline: 0;
      background-color: #DB3C21;
      color: white;
  
      &:after {
        transform: translate(0, 0);
      }
    }
  
    &:active {
      background-color: #A62D19;
      outline: 0;
      color: white;
      border-color: #A62D19;
  
      &:hover {
        background-color: #A62D19;
        outline: 0;
        color: white;
        border-color: #A62D19;
      }
    }
  }

/* CSS */
.button-60 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1rem;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;

  @include for-phone-only {
    white-space: normal;
    height: fit-content;
  }
}

//.button-60:active {
//  border-color: #4a4a4a;
//  outline: 0;
//}

//.button-60:focus {
//  border-color: #485fc7;
//  outline: 0;
//}

.button-60:hover {
  border-color: #b5b5b5;
}

.button-60:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}